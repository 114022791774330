.StopMachineConfirmationDialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0,0,0,0.5);
    z-index: 5;
}

.StopMachineConfirmationDialog {
    width: 50%;
    height: max-content;
    background-color: white;
    margin: 25% auto;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    padding: 1rem;
}

.StopMachineConfirmationDialog button:active {
    margin-top: calc(4px);
    vertical-align: -4px;
}

.StopMachineConfirmationDialog button:active:disabled {
    margin-top: inherit;
    vertical-align: inherit;
}

.StopMachineConfirmationDialog button:disabled {
    background-color: #888;
    border: none;
    pointer-events: none;
}

.cancel-button {
    background-color: #eee;
    color: black;
}

.StopMachineConfirmationDialog .confirmation-section {
    margin: 1rem;
}

.StopMachineConfirmationDialog .confirmation-section .explanation {
    margin-bottom: 1rem;
}

.StopMachineConfirmationDialog .machine-to-stop {
    text-decoration: underline 2px #0A0;
}