.login-user-section input[type="text"],
.login-user-section input[type="password"]
{
    padding: 0.5rem;
    border: none;
    border-bottom: 2px solid #444;
}

.login-user-section input[type="text"]:focus-visible,
.login-user-section input[type="password"]:focus-visible
{
    border-bottom: 1px solid #444;
}