.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

nav {
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.3);
  margin-bottom: 1rem;
  padding: 0.5rem;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav ul li {
  display: inline-block;
  margin: 0 0.5rem;
  padding: 0.5rem;
  color: black;
  border-radius: 4px;
}

nav ul li svg {
  vertical-align: text-top;
}

nav ul li:hover {
  background-color: #444;
  color: white;
}

nav ul .active li {
  background-color: #CD001A;
  color: white;
}

nav ul .active li {
  background-color: #CD001A;
}

button.logout {
  border: none;
  background-color: white;
  margin: 0 auto;
  padding: 0.25ex;

  float: right;
}

button.logout svg {
  vertical-align: bottom;
  font-size: 125%;
}

button.logout:active {
  margin: 0;
}