.ScaleMachineConfirmationDialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0,0,0,0.5);
    z-index: 5;
}

.ScaleMachineConfirmationDialog {
    width: 50%;
    height: max-content;
    background-color: white;
    margin: 25% auto;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    padding: 1rem;
}

.ScaleMachineConfirmationDialog button:active {
    margin-top: calc(4px);
    vertical-align: -4px;
}

.ScaleMachineConfirmationDialog button:active:disabled {
    margin-top: inherit;
    vertical-align: inherit;
}

.ScaleMachineConfirmationDialog button:disabled {
    background-color: #888;
    border: none;
    pointer-events: none;
}

.cancel-button {
    background-color: #eee;
    color: black;
}

.ScaleMachineConfirmationDialog .confirmation-section {
    margin: 1rem;
}

.ScaleMachineConfirmationDialog .confirmation-section .explanation {
    margin-bottom: 1rem;
}

.ScaleMachineConfirmationDialog .machine-to-stop {
    text-decoration: underline 2px #0A0;
}

.ScaleMachineConfirmationDialog .scale-options {
    text-align: left;
    margin: 1em auto;
    width: fit-content;
}

.ScaleMachineConfirmationDialog .scale-options .value {
    margin-left: 1em;
}

.ScaleMachineConfirmationDialog .scale-options .value .current-scale {
    margin-left: 1em;
}

.ScaleMachineConfirmationDialog .scale-options .value .current-scale::before {
    content: '[';
}
.ScaleMachineConfirmationDialog .scale-options .value .current-scale::after {
    content: ']';
}