.AdminView table {
    margin: 1rem auto;
}

.user td, .user th {
    padding: 0.25rem;
}

input[type=submit][disabled=""] {
    background-color: #444;
    border: none;
    cursor: not-allowed;
}


button,
input[type="submit"] {
    background-color: #0069d9;
    color: white;
    padding: 0.5rem;
    margin: 0.5rem;

    cursor: pointer;
    border: none;
    border-bottom: 4px solid rgba(0,0,0,0.25);
    border-radius: 4px;
}

button:active,
input[type="submit"]:active {
    border-bottom: none;
    margin-top: calc(0.5rem + 4px);
}

input[type=submit][disabled=""]:active {
    margin-top: 0.5rem;
}

.add-user-section {
    margin: 0 auto;
    width: max-content;
    padding: 1rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    position: relative;
}

button.close-panel-button {
    background: transparent;
    color: black;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 125%;
}

button.close-panel-button:hover {
    background: #444;
    color: white;
    border-radius: 50%;
    height: 3.25ex;
}

button.close-panel-button:active {
    margin: 0.5rem;
}

.add-user-section h3 {
    margin-top: 0;
}

.form-field {
    margin-bottom: 1rem;
}

.add-user-section input[type="text"],
.add-user-section input[type="password"]
{
    padding: 0.5rem;
    border: none;
    border-bottom: 2px solid #444;
}

.add-user-section input[type="text"]:focus-visible,
.add-user-section input[type="password"]:focus-visible
{
    border-bottom: 1px solid #444;
}

td svg {
    vertical-align: text-top;
}
td .permission {
    padding: 0.5ex 0;
    display: inline;
    margin: 0 0.5ex;
}

td .viewing .permission .permission-scope {
    display: none;
}

td .permission .permission-scope > div {
    display: inline-block;
    width: 21ex;
}

td .editing .permission .permission-scope > div.scope-multi-select {
    color: black;
}

td .editing .permission .permission-scope > div.scope-multi-select > div.react-select__control {
    background-color: rgba(255,255,255,0.3);
}

td .editing .permission.permission-machine {
    display: inline-block;
}

td .editing .permission.permission-machine > svg {
    margin-left: 1ex;
}


td .viewing .permission.disabled {
    visibility: hidden;
}

td .editing .permission.disabled {
    background-color: #ddd;
    border-radius: 4px;
}

td .editing .permission {
    cursor: pointer;
    padding: 0.5ex;
}

td .editing .permission.enabled {
    background-color: #CD001A;
    color: white;
    border-radius: 4px;
}
td .editing .permission.permission-machine {
    background-color: #f88;
    color: white;
}
td .editing .permission.permission-machine .react-select__placeholder {
}

th.field.actions {
    visibility: hidden;
}

td.registration-time,
th.registration-time {
    display: none;
}

.field.actions {
    padding: 0;
}

.field.actions button {
    margin: 0;
    background-color: #c0efff;
    color: black;
}
.field.actions button:active {
    border-top: 4px solid white;
}


.field.actions button.save {
    margin: 0;
    background-color: #0069d9;
    color: white;
}
