.ProjectView {
    text-align: left;
}

.ProjectView .view-header {
    text-align: center;
}

.project-header > * {
    display: inline-block;
}

.project-header .project-expand-icon {
    vertical-align: text-top;
    margin-right: 0.5ex;
}

.project-header .project-name {
    font-weight: bold;
}

.project-header .file-count {
    margin-left: 1rem;
    font-style: italic;
}

.project {
    margin: 1rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    padding: 0.5rem 0;
}
.project .project-header {
    padding: 0 0.5rem;
    cursor: pointer;
}

.project.collapsed .file-tree {
    display: none;
}

.project.collapsed .project-expand-icon.expanded-icon {
    display: none;
}
.project.expanded .project-expand-icon.collapsed-icon {
    display: none;
}

.project.empty .project-expand-icon {
    visibility: hidden; /* Take the space it needs, but don't show it. */
}

.project .file-tree {
    border-top: 1px solid #888;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-left: 1rem;
}

/* File tree */
.file-tree .directory-contents {
    padding-left: 1rem;
    border-left: 1px dotted #444;
    margin-left: 1ex;
    margin-bottom: 1rem;
}


.file-tree .directory-header {
    cursor: pointer;
    height: 1.5em;
}

.file-tree .directory-header:hover {
    background-color: #c0efff;
}

.file-tree .directory-header > * {
    display: inline-block;
}

.file-tree .directory-name {
    font-style: italic;
}

.file-tree .directory.collapsed > .directory-contents {
    display: none;
}

.file-tree .directory.collapsed > .directory-header .expanded-icon {
    display: none;
}
.file-tree .directory.expanded > .directory-header .collapsed-icon {
    display: none;
}

.file-tree .directory-header .action {
    background-color: #ddd;
    border-radius: 4px;
    padding: 0 0.5ex;
    margin-left: 1ex;
    height: 1.5rem;
}

.file-tree .directory-header .action svg {
    vertical-align: middle;
}

.file-tree .directory-header .action-description {
    display: none;
}

.file-tree .directory-header .action:hover .action-description {
    display: inline;
}