.MachineView table {
    margin: 1rem auto;
}

.instance td {
    padding: 0.25rem;
}

.instance:nth-child(odd) td[class!="action"] {
    /* Avoid coloring the action column.
     * Also, apply it on the TDs to avoid
     *  interfering with the instance state colors.
     */
    background-color: rgba(0,0,255,0.25);
}

.instance-state-80 {
    /* Stopped */
    color: white;
    background-color: #444;
}

.instance .actions {
    background-color: white;
}

.instance-state-80 a {
    color: white;
}

.instance-state-0, /* Pending */
.instance-state-64, /* Stopping */
.instance-state-init-pending {
    background-color: #c70;
    color: white;
}

.updating-marker.hidden {
    visibility: hidden;
}

.instance.instance-state-32 /* Shutting down */
{
    background-color: #faa;
}

.instance-state-48 /* Terminated */
{
    display: none;
}

button.start-instance-button {
    background-color: #0069d9;
    color: white;
    padding: 0.5rem;
    margin: 0.5rem;

    cursor: pointer;
    border: none;
    border-bottom: 4px solid rgba(0,0,0,0.25);
    border-radius: 4px;
}

button.scale-instance-button {
    background-color: #008800;
    color: white;
}

button.stop-instance-button {
    background-color: #ff8888;
    color: black;
    padding: 0.5rem;
    margin: 0.5rem;

    cursor: pointer;
    border: none;
    border-bottom: 4px solid rgba(0,0,0,0.25);
    border-radius: 4px;
}

button.start-instance-button:active, button.stop-instance-button:active {
    border-bottom: none;
    margin-top: calc(0.5rem + 4px);
}
